import React from "react";
import Panel from "../components/panel";
import Banner from "../components/banner";
import Alert from "../components/esp/alert";
import GoogleAnalytics from "../components/analytics";
import BodyOverride from "../components/esp/bodyOverride";
import espContent from "../esp/content.json";

const getResizedImg = (node, size) => {
    if (node.frontmatter === undefined) return '';
    let srcSet = node.frontmatter.featuredImage.childImageSharp.fluid.srcSet;
    console.log(srcSet);
    console.log(size);
    let imgPath = srcSet.split(',').find(elem => elem.indexOf(size.toString()) !== -1).trim().split(' ')[0] || "";
    return imgPath || '';
}

export default ({ pageContext, data }) => {

    let assetBanner = data.assetCountdown.nodes[0];
    let assetPanel = data.assetHeader.nodes[0];
    const bannerImg = getResizedImg(assetBanner, 1024);
    const panelImg = getResizedImg(assetPanel, 1024);
    let news = espContent.news;
    console.log(news);

    return (
        <div>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-K14SPPV5EN"></script>
            <GoogleAnalytics module="ESPNEWS" />
            <BodyOverride />
            <Banner title="Bem vindo ao ESP" imgPath={bannerImg}>
            </Banner>
            <Alert level='error' text="Serviço com instabilidade"/>
            {news.length > 0 && <Panel title="Novidades" imgPath={panelImg}>
                <ul>
                    {news.map(newsItem => (
                        <li key={`key${news.indexOf(newsItem)}`}>
                            <div>
                                <b>{newsItem.title}</b>
                                <p>{newsItem.text}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </Panel>}
        </div>
    )
};

export const query = graphql`
query {
    assetCountdown: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "banner"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 95) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    assetHeader: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "panel-header"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }
}
`