import React from "react"
import { css } from "@emotion/core"

export default (props) => {

    const componentCss = css`
        margin-bottom: 10px;
        width: 1024px;
    `

	let color, textColor;
	switch (props.level) {
		case 'info':
			color = 'light-blue';
			textColor = 'black';
			break;
		case 'warn':
			color = 'yellow';
			textColor = 'black';
			break;
		case 'error':
			color = 'red';
			textColor = 'black';
			break;
		default:
			color = 'light-blue';
			break;
	}

    return (
        <div css={componentCss}>
			<h1 style={{backgroundColor: color, textAlign: 'center', border: '2px solid', textTransform: 'uppercase', color: textColor}}>{props.text}</h1>
        </div>
    )
}